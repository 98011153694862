.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header h1 {
  font-size: 2rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.DiffField,
.InputArea {
  margin: auto;
  width: 1000px;
}

.InputArea {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: solid thin #282c34;
}

.InputArea textarea {
  width: 400px;
  height: 300px;
  margin: 1rem;
}

.DiffField {
  text-align: left;
  line-height: 1.5rem;
  font-family: "Courier New", Courier, monospace;
}

.diff--1 {
  background: #ff9999;
}

.diff-1 {
  background: #99ff99;
}

.DiffField .row {
}

.DiffField .ButtonArea {
  padding-bottom: 1rem;
}

.DiffField .ButtonArea button {
  margin-right: 1rem;
}

.DiffField .row:hover {
  background: #ffff99;
  cursor: pointer;
}

.DiffField .row.unselected .diff--1,
.DiffField .row.unselected .diff-1 {
  text-decoration: none;
  background: #bbb;
}

footer {
  text-align: left;
  font-size: 0.8rem;
  margin: auto;
  margin-top: 2rem;
  width: 1000px;
}
